
.emp-profile {
  padding: 3%;
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 0.5rem;
  background: #fff;
}
.profile-img {
  text-align: center;
}
.profile-img img {
  width: 70%;
  height: 100%;
}
.profile-img .file {
  position: relative;
  overflow: hidden;
  margin-top: -20%;
  width: 70%;
  border: none;
  border-radius: 0;
  font-size: 15px;
  background: #212529b8;
}
.profile-img .file input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
}
.profile-head h4 {
  color: #333;
}
.profile-head h6 {
  color: #0062cc;
}
.profile-edit-btn {
  border: none;
  border-radius: 1.5rem;
  width: 70%;
  padding: 2%;
  font-weight: 600;
  color: #6c757d;
  cursor: pointer;
}
.proile-rating {
  font-size: 12px;
  color: #818182;
  margin-top: 5%;
}
.proile-rating span {
  color: #495057;
  font-size: 15px;
  font-weight: 600;
}
/* .profile-head .nav-tabs {
  margin-bottom: 5%;
}
.profile-head .nav-tabs .nav-link {
  font-weight: 600;
  border: none;
}
.profile-head .nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid #0062cc;
} */
.profile-work {
  padding: 2%;
}
.profile-work p {
  font-size: 12px;
  color: #818182;
  font-weight: 600;
  margin-top: 10%;
}
.profile-work a {
  text-decoration: none;
  color: #495057;
  font-weight: 600;
  font-size: 14px;
}
.profile-work ul {
  list-style: none;
}
.profile-tab label {
  font-weight: 800;
}
.profile-tab h5 {
  /* color: #9197ae; */
  color: #636363;

}
.profile-tab p {
  font-weight: 800;

}

.profile-work .fot-list li {
  margin-bottom: 8px;
}
.profile-work .fot-list li a {
  color: #9197ae;
  font-size: 15px;
  line-height: 1.2;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  position: relative;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.profile-work .fot-list li a::before {
  content: "\f054";
  position: absolute;
  font-family: "Font Awesome 5 pro";
  font-size: 13px;
  color: #9197ae;
  left: 0;
  top: 5px;
  line-height: 1;
  opacity: 0;
  visibility: hidden;
}
.profile-work .fot-list li a:hover,
.profile-work .fot-list li a:focus {
  color: #02b663;
  /* padding-left: 20px; */
}
.profile-work .fot-list li a:hover::before,
.profile-work .fot-list li a:focus::before {
  color: #02b663;
  opacity: 1;
  visibility: visible;
}

.link-list li a::before {
  display: none;
}
.link-list li a i {
  color: #02b663;
  margin-right: 10px;
}


.profile-head {
  transform: translateY(10rem)
}

.cover {
  background-image: url('../../images/Dashboard/profile-cover.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  /* width: 100%; */
  /* height: 300px; */
}

/* body {
  background: #654ea3;
  background: linear-gradient(to right, #e96443, #904e95);
  min-height: 100vh;
  overflow-x: hidden
} */

.w-128 {
  width: 8.8rem !important;
}
.h-128 {
  height: 8.8rem !important;
}
/* .-mt-64 {
  margin-top: 8.4rem !important;
} */
.no_social_media{
  font-size: 14px !important;
}

.profileCard{
  border-radius: 50px;
}