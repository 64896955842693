html {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
body{
    color:rgba(0, 0, 0, 0.87)
}

.form-control:focus {
    border: 2px solid #02b663 !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(2,182,99, 0.5) !important;
    
}

.profile-button {
    background: rgb(99, 39, 120);
    box-shadow: none;
    border: none
}

.profile-button:hover {
    background: #02b663
}

.profile-button:focus {
    background: #02b663;
    box-shadow: none
}

.profile-button:active {
    background: #02b663;
    box-shadow: none
}

.back:hover {
    color: #02b663;
    cursor: pointer
}

.labels {
    font-size: 15px
}
.labels i {
    color:#02b663;
}

.add-experience:hover {
    background: #02b663;
    color: #fff;
    cursor: pointer;
    border: solid 1px #02b663
}
.editButton{
    border-radius: 5px !important;
    padding: 12px 28px 12px 28px !important;

}
.edit_save{
    color: white !important;
    background: #1a1e2d !important;
    border-radius: 5px !important;

}

.edit_save:hover{
    border-radius: 35px !important;

}