$white: #fff;
$facebook-blue: #4267b2;
$button-active-blue: #284a8d;

.facebook-btn{
  width: 100%;
  height: 45px;
  background-color: $facebook-blue;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}

.facebook-icon{
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
  color: $white;
}

.btn-text{
  color: $white;
  font-size: 14px;
  position: relative;
  top: 50%;
  // -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}


// .facebook-btn {
//   width: 200px;
//   height: 45px;
//   position: relative;
//   background-color: $facebook-blue;
//   border-radius: 2px;
//   box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
//   .facebook-icon-wrapper {
//     position: absolute;
//     margin-top: 1px;
//     margin-left: 1px;
//     width: 40px;
//     height: 40px;
//     border-radius: 2px;
//     background-color: $facebook-blue;
//   }
//   .facebook-icon {
//     position: absolute;
//     margin-top: 11px;
//     margin-left: 11px;
//     width: 18px;
//     height: 18px;
//     color: $white;
//   }
//   .btn-text {
//     float: right;
//     margin: 11px 11px 0 0;
//     color: $white;
//     font-size: 14px;
//     letter-spacing: 0.2px;
//     font-family: "Roboto";
//   }
//   &:hover {
//     box-shadow: 0 0 3px black;
//     cursor: pointer;
//   }
//   &:active {
//     background: $button-active-blue;
//     .btn-text {
//       color: $white;
//     }
//     .facebook-icon-wrapper {
//       background-color: $button-active-blue;
//     }
//   }
// }
// /* Using SCSS variables to store breakpoints */
// $breakpoint-tablet: 600px;
// @media (max-width: $breakpoint-tablet) {
//   .facebook-btn {
//     width: 100%;
//     .btn-text {
//       float: none;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       top: 50%;
//       -ms-transform: translateY(30%);
//       transform: translateY(30%);
//       // text-align: center;
//       font-size: 18px;
//     }
//     .facebook-icon {
//       width: 22px;
//       height: 22px;
//     }
//   }
// }

@import url(https://fonts.googleapis.com/css?family=Roboto:500);
