.mig {
    max-width: 100%;
    height: 10vw;
}
.cat{
    height: auto;
    width: 170px;
    object-fit: 'cover'
}
/* @media only screen and (min-width: 800px) and (min-height: 768px){
.mig {
    max-width: 100%;
    height: 5.5vw;
}
.cat {
    width:170px;
    max-height: auto;
}
} */

@media only screen and (max-width: 800px) { 
 
    .mig {
        max-width: 100%;
        height: 200px;
    }
    .cat {
        max-width: 100%;
        max-height: 100%;
    }
    }