$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;

.google-btn {
  width: 100%;
  height: 45px;
  position: relative;
  background-color: $white;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  // .google-icon-wrapper {
  //   position: absolute;
  //   margin-top: 1px;
  //   margin-left: 1px;
  //   width: 40px;
  //   height: 40px;
  //   border-radius: 2px;
  //   background-color: $white;
  // }
  .google-icon {
    position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
  // color: $white;
  }
  .btn-text {
  display: flex;
  justify-content: center;
  color: black;
  font-size: 14px;
  position: relative;
  top: 50%;
  // -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  }
  &:hover {
    box-shadow: 0 0 3px black;
    cursor: pointer;
  }
  &:active {
    background: $button-active-blue;
    .btn-text {
      color: $white;
    }
  }
}
/* Using SCSS variables to store breakpoints */
$breakpoint-tablet: 600px;
@media (max-width: $breakpoint-tablet) {
  .google-btn {
    width: 100%;
    .google-icon {
      width: 22px;
      height: 22px;
    }
  }
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);
