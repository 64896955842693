@media screen and (max-width: 460px) {
    .page-title-area {
      height: 50vh !important; /* Modifier la hauteur pour les grands écrans */
      
    }
    .koh {
        font-size: 23px;
    }
    .sub-title {
        display: none !important;
        display: none !important;
    }
  }

  @media screen and (max-width: 460px) {

  }

  @media only screen and (min-width: 200px) and (max-width: 767px)  {

  }