@media only screen and (max-width: 767px) { 
.letext {
    margin-left: 23px;
    justify-content: center;
    
}
.bort .card {
    border: none;
}
.card {
    margin-bottom: 8px;
}
}