.splide__arrow--prev, .splide__arrow--next {
    background: #fed857;
    color: black !;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    text-align: center;
  }
  
  .splide__arrow--prev {
    left: -25px;
  }
  
  .splide__arrow--next {
    right: -20px;
  }
  
  .splide__arrow svg {
    fill: #fff;
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }
  .splide__arrow {
    color: red !important;
  }