.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.qwerty {
  background-color: aqua;
}


.star {
  color: red;
}
.note {
  color: #716ca2;
}

/* Kamamini color 1 #008000
Kamamini color 2 #007200 
Kamamini color 3 #006400 */

/* Fande color
green #02b663
rgb(2,182,99)
*/

.k_green {
  background-color: #02b663 !important ;
  color: #fff !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
}


.small {
  width: 150px;
  height: 150px;
}

.kamamini-input .input-group input:focus {
  border: 2px solid #02b663 !important;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(2,182,99, 0.5) !important;
}
.kamamini-input .input-group textarea:focus {
  border: 2px solid #02b663 !important;
   box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(2,182,99, 0.5) !important;
}
.image img{
  max-width:100%;
  height:auto;
}
.checkout-card {
  border: 1px solid rgba(0,0,0,.125) !important;
  /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(2,182,99, 0.5) !important; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.checkout-image{
  width: 40px;
}

.no-margin{
  margin: 0px !important;
}
.normal-font{
  font-weight: 500 !important;
}
.border-info-custom {
  border: 1px solid #1a1e2d !important;
}
.edit_padding{
  padding: 14px 14px 14px 14px !important;

}

@media screen and (max-width: 993px) {
  .card-body li {
    padding: 5px !important;
  }
  .card-body li p {
    font-size: 12px !important;
  }

  
}
.mui_error p{
  color: #f44336 !important;
}
.link{
  text-decoration: underline;
}
.link:hover{
  text-decoration: none;
  color: #02b663;
}
.scroll-to-top {
  position: fixed;
  bottom: 10%;
  right: 2%;
  padding: 0.8rem 1rem;
  border-radius: 1rem;
  background-color: #fed857;
  color: #fff;
  z-index: 1999;
  cursor: pointer;
  transition: 0.3s;
  animation: bounce 2s ease-in-out infinite;
}
.scroll-to-top i {
  width: 1.3rem;
  height: 1.3rem;
  fill: #fff;
}
.scroll-to-top:hover {
  background-color: #ffc400;
}
.date {
  font-size: 15px;
  /* color: ; */
}
.border-top-kamamini{
  border-top: 2px solid #e7e7e7;
}
.scrol-container {
  width: 100%;
  height: 100%;
  overflow: scroll;
  /* border: 5px solid var(--ck-color-base-border); */
}
.scrol-container > * {
  display: flex;
  width: 200%;
}



/* .checkout-card .card-body {
  padding: 1.25rem !important;
} */

/* .player-wrapper {
  width: auto; 
  height: auto; 
}

.react-player {
  padding-top: 56.25%; 
  position: relative; 
}
.react-player > div {
  position: absolute; 
} */
.paragraph__color{
  color: black;
}

.text-underline-hover :hover {
  text-decoration: underline !important;
  cursor: pointer !important;
}

.custom-project-card img {
  width: 90%;
  height: 150px; 
  object-fit: cover;
}