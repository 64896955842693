* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.ody {
  justify-content: center;
  align-items: center;
  height: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat";
  color: rgb(56,56,56);
}
.wrapperu {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.wrapperu h1 {
  font-size: 3rem;
  margin-top: 10px;
}
.wrapperu .messageu {
  font-size: 1rem;
  padding: 20px;
  width: 60%;
  text-align: center;
}
.wrapperu .btn {
  background: rgb(0,195,154);
  padding: 20px;
  font-size: 1.5rem;
  text-decoration: none;
  color: #fff;
}
.wrapperu .btn:hover {background: rgb(0,231,201);}
/* .wrapperu .copyRightsu {margin-top: 50px;} */


  