.raw{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
}
.premier{
    margin: 10px 50px 20px;
    display: center;
    text-align: start;
    justify-content:center;
}
.border{
    border-radius: 10px;
}


.photo{
    max-width: 60%;
    height: auto;
    float: right;
}

.liste{
    margin: 10px 50px 20px;
    overflow-x: hidden;
}

.liste a
{
	color: black;
	padding: 10px 15px 10px 16px;
	text-decoration: none;
	font-size: 15px;
	display: block;
}

.li> li :hover {
    color: #02A056
}

.privé{
    margin-left: 20px;
}

.sidenav{
    position: relative;
}

.sidenav.fixed{
    position: fixed;
	top: 6%;
    margin-top: 3rem
}
.terms__li{
    margin-left: 3rem;
}

.terms__p{
    margin-left: 1rem;
}

.liste a:hover
{
	background: #02A056;
}

.terms__h2
{
	padding: 10px 15px 10px 16px;
	color: black;
	border-bottom-style: solid;
}