.perk__card{
    display: flex;
    padding: 10px;
    border-radius: 5px;
    border:2px solid #efefef;
    cursor: pointer;
    width: 100%;
    /* box-shadow: 0px 0px 10px rgba(0,0,0,0.1); */

}
.radio__container{
    padding: 10px;
}
.perk__content{
    width: 100%;
    padding: 10px;
}
.perk__content h3{
    font-weight: 400;
    font-size: 1.2rem;
}
.perk__content h2 {
    font-weight: 500;
    font-size: 1.5rem;
}
.perk__content p {
    color: #656969;
}
.green__chip{
    background-color: #02b663;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: 500;
}
#test:hover{
    border: #02b663 2px solid;
    transform: scale(1.05);
}
.perk__selected{
    border: #02b663 2px solid;
    transform: scale(1.05);
}

@media screen and (max-width: 768px) {
    .perk__card{
        flex-direction: column;
    }
    .perk__content{
        width: 100%;
    }
    .radio__container{
        padding: 5px;
    }
    .perk__content h3{
        font-size: 1rem;
    }
    .perk__content h2 {
        font-size: 1.2rem;
    }
    .perk__content p {
        font-size: 0.8rem;
    }
    .green__chip{
        font-size: 0.7rem;
    }
    
}
    
