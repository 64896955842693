
.kamamini_editor_container{
  border: 10px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}
.ck.ck-toolbar{
      /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);
  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border: 3px solid var(--ck-color-toolbar-border);

}
.ck.ck-editor__editable_inline{
    border: 5px solid var(--ck-color-toolbar-border) !important;
    padding: calc(2 * var(--ck-spacing-large));
    background: var(--ck-color-base-foreground) !important;
    height: 650px;

    /* Make it possible to scroll the "page" of the edited content. */
    overflow-y: scroll !important;

  /* Keep the "page" off the boundaries of the container. */
  padding:0cm 1cm 1cm !important;

  border-radius: var(--ck-border-radius) !important;
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

 
}


/* Set the default font for the "page" of the content. */
.kamamini_editor_container .ck-content,
.kamamini_editor_container .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}
/* Adjust the headings dropdown to host some larger heading styles. */
.kamamini_editor_container .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
  Preserve the relative scale, though. */
.kamamini_editor_container
  .ck-heading-dropdown
  .ck-list
  .ck-button:not(.ck-heading_paragraph)
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.kamamini_editor_container .ck-content h2,
.kamamini_editor_container .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: bold;
}

.kamamini_editor_container .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}
/* 
Set the styles for "Heading 2". */
.kamamini_editor_container .ck-content h3,
.kamamini_editor_container .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: bold;
  /* color: hsl(203, 100%, 50%); */
}
.kamamini_editor_container .ck-content a[target="_blank"]::after,
.kamamini_editor_container  .ck-button__label {
  content: '\29C9';
  color: #0000EE;
}
.kamamini_editor_container .ck-content a,
.kamamini_editor_container  .ck-button__label {
  color: #0000EE;
}
.kamamini_editor_container .ck-content ul li,
.kamamini_editor_container .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  list-style: inside;
}
.kamamini_editor_container .ck-content ol li,
.kamamini_editor_container .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
list-style: decimal;
}
.kamamini_editor_container .ck-content img,
.kamamini_editor_container .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  max-width:50% !important;
  height:auto
}



.kamamini_editor_container
  .ck-heading-dropdown
  .ck-heading_heading2.ck-on
  .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.kamamini_editor_container .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.150em;
}

/* Set the styles for "Heading 3". */
.kamamini_editor_container .ck-content h4,
.kamamini_editor_container .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.kamamini_editor_container .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.350em;
}

/* Set the styles for "Paragraph". */
.kamamini_editor_container .ck-content p {
  font-size: 1em;
  line-height: 1.63em;
  padding-top: 0.5em;
  margin-bottom: 0.150em;
}
.kamamini_editor_container .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
.kamamini_editor_container .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
